'use client';

import React, { useEffect, useState } from 'react';
import { Button, Text, Flex, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody, useDisclosure, PopoverHeader, PlacementWithLogical } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { useTheme } from 'next-themes';
import { Settings2Icon } from 'lucide-react';
import SettingsLayout from './components/SettingsLayout';
import StandardGradient from '../text/StandardGradient';
import { NAVBAR_ITEMS_HEIGHT } from '../wallet/NavbarConnect/NavbarConnectButton';
interface GlobalSettingsPopoverProps {
  placement?: PlacementWithLogical;
  bg?: string;
}
export default function GlobalSettingsPopover({
  placement = 'auto',
  bg
}: GlobalSettingsPopoverProps) {
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  const {
    theme,
    resolvedTheme
  } = useTheme();
  const [mounted, setMounted] = useState(false);
  const controls = useAnimation();
  useEffect(() => {
    setMounted(true);
  }, []);
  const handleClick = () => {
    onOpen();
  };
  const handleHoverStart = () => {
    controls.start({
      rotate: 180,
      transition: {
        duration: 0.2
      }
    });
  };
  const handleHoverEnd = () => {
    if (!isOpen) {
      controls.start({
        rotate: 0,
        transition: {
          duration: 0.2
        }
      });
    }
  };
  useEffect(() => {
    if (isOpen) {
      controls.start({
        rotate: 360,
        transition: {
          duration: 0.2
        }
      });
    } else {
      controls.start({
        rotate: 0,
        transition: {
          duration: 0.2
        }
      });
    }
  }, [isOpen, controls]);
  const iconColor = mounted && (resolvedTheme === 'dark' ? 'white' : 'black');
  if (!mounted) {
    return null;
  }
  return <Popover variant="settings" isOpen={isOpen} onClose={onClose} placement={placement} data-sentry-element="Popover" data-sentry-component="GlobalSettingsPopover" data-sentry-source-file="GlobalSettingsPopover.tsx">
      <PopoverTrigger data-sentry-element="PopoverTrigger" data-sentry-source-file="GlobalSettingsPopover.tsx">
        <Flex h="100%" alignItems="flex-end" justifyContent="center" data-sentry-element="Flex" data-sentry-source-file="GlobalSettingsPopover.tsx">
          <Button onClick={handleClick} variant="colormodetoggle" height={NAVBAR_ITEMS_HEIGHT} alignSelf="flex-end" justifySelf="flex-end" background={bg} onMouseEnter={handleHoverStart} onMouseLeave={handleHoverEnd} data-sentry-element="Button" data-sentry-source-file="GlobalSettingsPopover.tsx">
            <motion.div animate={controls} data-sentry-element="unknown" data-sentry-source-file="GlobalSettingsPopover.tsx">
              <Settings2Icon size={18} color={iconColor} data-sentry-element="Settings2Icon" data-sentry-source-file="GlobalSettingsPopover.tsx" />
            </motion.div>
          </Button>
        </Flex>
      </PopoverTrigger>
      <PopoverContent p="2" data-sentry-element="PopoverContent" data-sentry-source-file="GlobalSettingsPopover.tsx">
        <PopoverHeader color="text.100" data-sentry-element="PopoverHeader" data-sentry-source-file="GlobalSettingsPopover.tsx">
          <Flex direction="row" align="center" data-sentry-element="Flex" data-sentry-source-file="GlobalSettingsPopover.tsx">
            <Settings2Icon size={18} color={iconColor} data-sentry-element="Settings2Icon" data-sentry-source-file="GlobalSettingsPopover.tsx" />
            <StandardGradient fontSize="20px" data-sentry-element="StandardGradient" data-sentry-source-file="GlobalSettingsPopover.tsx">Settings</StandardGradient>
          </Flex>
        </PopoverHeader>
        <PopoverArrow data-sentry-element="PopoverArrow" data-sentry-source-file="GlobalSettingsPopover.tsx" />

        <PopoverBody data-sentry-element="PopoverBody" data-sentry-source-file="GlobalSettingsPopover.tsx">
          <SettingsLayout data-sentry-element="SettingsLayout" data-sentry-source-file="GlobalSettingsPopover.tsx" />
        </PopoverBody>
        <Flex w="100%" mt="2" justify="center" data-sentry-element="Flex" data-sentry-source-file="GlobalSettingsPopover.tsx">
          <Button variant="toggleon" w="100px" onClick={onClose} data-sentry-element="Button" data-sentry-source-file="GlobalSettingsPopover.tsx">
            Save
          </Button>
        </Flex>
      </PopoverContent>
    </Popover>;
}