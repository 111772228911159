import { mode } from '@chakra-ui/theme-tools';

export const Card = {
 
  variants: {
    latestswaps: (props) => ({
      container: {
        bg: 'insetComponent', 
        color: 'text.100', 
        borderRadius: '12px', 
        padding: '2', 
        borderWidth: mode('1px', '0px')(props), 
        borderColor: mode('rgba(0, 0, 0, 0.1)', 'rgba(255, 255, 255, 0.15)')(props),
        boxShadow: 'outsetComponentShadow', 
      }
    }),

    outline: (props) => ({
      container: {
        background: 'outsetComponent', 
        borderWidth: '0px',
        boxShadow: 'outsetComponentShadow', 
        borderColor: mode('rgba(0, 0, 0, 0.05) !important', 'rgba(255, 255, 255, 0.15) !important')(props),
      }, 
      body: {
        paddingX: '8px', 
        paddingY: '2px', 

      }
    }),
    inset: (props) => ({
      container: {
        background:  'insetComponent', 
        boxShadow: 'insetComponentShadow', 
        borderWidth: '1px', 
        borderColor: mode('rgba(0, 0, 0, 0.1) !important', 'rgba(255, 255, 255, 0.15) !important')(props),
        padding: '8px', 
        borderRadius: '12px', 
      }, 
      header: {
        padding: '2px', 
      }, 
      body: {
            background: 'outsetComponent !important',
            boxShadow: 'popover.xs',
            borderRadius: '12px !important',
            padding: '12px !important',
          },
    }), 
    poolcard: (props) => ({
      container: {
        background: 'outsetComponent', 
        boxShadow: 'outsetComponentShadow', 
        borderWidth: '0px', 
        borderColor: mode('rgba(0, 0, 0, 0.05) !important', 'rgba(255, 255, 255, 0.1) !important')(props),
        padding: '8px', 
        borderRadius: '14px', 
      }, 
      header: {
        padding: '2px', 
      }, 
      body: {
            background: 'insetComponent',
            borderWidth: '0px', 
            boxShadow: 'insetComponentShadow',
            borderRadius: '12px',
            padding: '8px',
          },
    }), 
    outset: (props) => ({
      container: {
        background: 'outsetComponent', 
        boxShadow: 'outsetComponentShadow', 
        borderWidth: '1px', 
        borderColor: mode('rgba(0, 0, 0, 0.05) !important', 'rgba(255, 255, 255, 0.1) !important')(props),
        padding: '8px', 
        borderRadius: '16px', 
        width: '100%', 
        justifyContent: 'center', 
        alignItems: 'center', 

      }, 
      header: {
        padding: '2px', 
      }, 
      body: {
            width: '100%', 
            background: 'insetComponent', 
            boxShadow: 'insetComponentShadow',
            borderRadius: '12px !important',
            padding: '12px !important',
          },
    }), 
  },
  defaultProps: {

  },
};

// settings: (props) => ({
//   content: {
//     width: 'fit-content !important',
//     borderRadius: '16px !important',
//     background: 'alphaGradient.100 !important',
//     boxShadow: 'popover.dropShadow',
//     borderWidth: '1px', 
//     borderColor: mode('rgba(0, 0, 0, 0.05) !important', 'rgba(255, 255, 255, 0.1) !important')(props),
//     padding: '8px !important',
//   },
//   header: {
//     color: 'text.100 !important',
//     marginBottom: '12px !important',
//   },
//   body: {
//     background: 'alphaGradient.200 !important',
//     boxShadow: 'popover.xs',
//     borderRadius: '12px !important',
//     padding: '12px !important',
//   },
//   arrow: {
//     background: '#F0EEE6 !important',
//     boxShadow: 'popover.arrow',
//   },
//   closeButton: {}
// }),