import React from 'react';
import { Box, Text, useTheme } from '@chakra-ui/react';
import Link from 'next/link';
import DocumentSimple from '~/app/components/icons/Simple/DocumentSimple';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
import DropdownLinkTemplate from './DropdownLinkTemplate';
export default function Documentation() {
  const networkConfig = useNetworkConfig();
  return <>
      <Link href={networkConfig.socialLinks.documents} target="_blank" rel="noopener noreferrer" data-sentry-element="Link" data-sentry-source-file="Documentation.tsx">
        <DropdownLinkTemplate icon={DocumentSimple} text="Documentation" data-sentry-element="DropdownLinkTemplate" data-sentry-source-file="Documentation.tsx" />
      </Link>
    </>;
}