/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import { Button, Flex, useColorMode } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import { Moon, Sun } from 'react-feather';
import { NAVBAR_ITEMS_HEIGHT } from '../wallet/NavbarConnect/NavbarConnectButton';
export default function ColorModeToggle() {
  const [mounted, setMounted] = useState(false);
  const {
    theme,
    setTheme
  } = useTheme();
  const {
    setColorMode
  } = useColorMode();
  function toggleColorMode() {
    setTheme(theme == 'light' ? 'dark' : 'light');
  }
  const animationSun = {
    initial: {
      rotate: 0,
      scale: 0,
      opacity: 0
    },
    animate: {
      rotate: 90,
      scale: 1,
      opacity: 1
    },
    exit: {
      rotate: 0,
      scale: 0,
      opacity: 0
    }
  };
  const animationMoon = {
    initial: {
      rotate: 90,
      scale: 0,
      opacity: 0
    },
    animate: {
      rotate: 0,
      scale: 1,
      opacity: 1
    },
    exit: {
      rotate: 90,
      scale: 0,
      opacity: 0
    }
  };
  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    setColorMode(theme);
  }, [theme]);
  if (!mounted) {
    return <Button variant="secondarytrans" p="0" isDisabled>
        <Moon size={18} />
      </Button>;
  }
  return <Flex h="100%" alignItems="flex-end" justifyContent="center" mx="2" data-sentry-element="Flex" data-sentry-component="ColorModeToggle" data-sentry-source-file="index.tsx">
      <Button onClick={toggleColorMode} variant="colormodetoggle" height={NAVBAR_ITEMS_HEIGHT} alignSelf="flex-end" justifySelf="flex-end" data-sentry-element="Button" data-sentry-source-file="index.tsx">
        <AnimatePresence initial={false} data-sentry-element="AnimatePresence" data-sentry-source-file="index.tsx">
          {theme === 'light' ? <motion.i {...animationSun}>
              <Sun size={18} color="black" />
            </motion.i> : <motion.i {...animationMoon}>
              <Moon size={18} />
            </motion.i>}
        </AnimatePresence>
      </Button>
    </Flex>;
}