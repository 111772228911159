const universalBaseShadows = {
  boxShell: '0 -15px 30px -15px rgba(10, 29, 86, 0.75), 0 0 12px #000',
};

export const baseShadows = {
  light: {
    ...universalBaseShadows,

    componentGlow:
      '0 0 36px rgb(55, 192, 223, 0.25), 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.05)',

    mastheadShadow: `
  -1px -1px 1px rgba(255, 255, 255, 0.8),
  1px 1px 1px rgba(0, 0, 0, 0.3),
  1px 1px 2px rgb(55, 192, 223, 0.4),
  -1px 1px 1px rgba(255, 255, 255, 0.7),
  -1px -1px 2px rgb(1, 84, 254, 0.4),
  1px -1px 1px rgba(0, 0, 0, 0.3), 
  0 0 36px rgb(55, 192, 223, 0.25)
 `,
  },

  dark: {
    ...universalBaseShadows,
    componentGlow:
      '0 0 36px rgb(55, 192, 223, 0.25), 0 0 24px rgba(55, 192, 223, 0.35), 20px 20px 50px 0px rgba(0, 0, 0, 0.25), 10px 10px 25px 0px rgba(0, 0, 0, 0.18), 2px 2px 11px 0px rgba(0, 0, 0, 0.19), 0px -1px 1px 0px #FFFFFF40',
    // mash up the outsetComponent with a glow
    mastheadShadow: 'none',
  },
};
