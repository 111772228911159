import React from 'react';
import { Button, useMediaQuery } from '@chakra-ui/react';
export const NAVBAR_ITEMS_HEIGHT = '30px';
export const NavbarConnectButton = ({
  openConnectModal
}) => {
  const [isLgOrLarger] = useMediaQuery('(min-width: 1000px)');
  return <Button variant="toggleon" onClick={openConnectModal} type="button" height={NAVBAR_ITEMS_HEIGHT} fontSize="15px" borderRadius="10px" aria-label="Open Connect Wallet Modal" fontWeight="500" color="text.100" data-sentry-element="Button" data-sentry-component="NavbarConnectButton" data-sentry-source-file="NavbarConnectButton.tsx">
      {isLgOrLarger ? 'Connect Wallet' : 'Connect'}
    </Button>;
};