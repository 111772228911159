'use client';

import React, { useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Link from 'next/link';
import Image from 'next/image';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
export function DesktopNavIcon() {
  const networkConfig = useNetworkConfig();
  return <>
      <Flex alignItems="center" ml="2" mr="4" cursor="pointer" data-sentry-element="Flex" data-sentry-source-file="DesktopNavIcon.tsx">
        <Link href="/" aria-label="Home" data-sentry-element="Link" data-sentry-source-file="DesktopNavIcon.tsx">
          <Box width="40px" height="40px" position="relative" data-sentry-element="Box" data-sentry-source-file="DesktopNavIcon.tsx">
            <Image src={networkConfig.dexIconInRepo} fill alt="App Icon" sizes="40px" data-sentry-element="Image" data-sentry-source-file="DesktopNavIcon.tsx" />
          </Box>
        </Link>
      </Flex>
    </>;
}