'use client';

import Link from 'next/link';
import { useState, useEffect } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { getLocalStorage, setLocalStorage } from '~/lib/storageHelper/storageHelper';
export default function CookieBanner() {
  const [cookieConsent, setCookieConsent] = useState(undefined);
  useEffect(() => {
    const storedCookieConsent = getLocalStorage('cookie_consent', null);
    // console.log('storedCookieConsent', storedCookieConsent);
    setCookieConsent(storedCookieConsent);
  }, []);
  useEffect(() => {
    if (cookieConsent !== null && cookieConsent !== undefined) {
      const newValue = cookieConsent ? 'granted' : 'denied';
      if (typeof window.gtag !== 'undefined') {
        window.gtag('consent', 'update', {
          analytics_storage: newValue
        });
      }
      setLocalStorage('cookie_consent', cookieConsent);
    }
  }, [cookieConsent]);
  if (cookieConsent !== null) {
    return null;
  }
  return <Box my={10} mx="auto" w={{
    base: '95%',
    lg: '500px'
  }} position="fixed" bottom={0} left={0} right={0} px={{
    base: 3,
    md: 4
  }} py={3} display="flex" justifyContent="space-between" alignItems="center" flexDirection={{
    base: 'column',
    sm: 'row'
  }} gap={4} borderWidth="1px" borderColor="darkborder" bg="transparent" backdropFilter="blur(12px)" boxShadow="outsetComponentShadow" rounded="18px" zIndex={50} data-sentry-element="Box" data-sentry-component="CookieBanner" data-sentry-source-file="CookierBanner.tsx">
      <Flex justify="center" textAlign="center" color="text.100" data-sentry-element="Flex" data-sentry-source-file="CookierBanner.tsx">
        <Link href="/info/cookies" data-sentry-element="Link" data-sentry-source-file="CookierBanner.tsx">
          <Text fontSize="14px" data-sentry-element="Text" data-sentry-source-file="CookierBanner.tsx">
            We use{' '}
            {/* <Text as="span" fontWeight="bold" color="sky.400"> 
             lol what is 'sky' 
             */}
            <Text as="span" fontWeight="600" data-sentry-element="Text" data-sentry-source-file="CookierBanner.tsx">
              cookies
            </Text>{' '}
            on our site to enhance browsing experience.
          </Text>
        </Link>
      </Flex>

      <Flex gap={2} direction="column" data-sentry-element="Flex" data-sentry-source-file="CookierBanner.tsx">
        <Button variant="toggleon" height="30px" w="100px" color="text.100" onClick={() => setCookieConsent(true)} data-sentry-element="Button" data-sentry-source-file="CookierBanner.tsx">
          Allow
        </Button>
        <Button variant="toggleoff" height="30px" w="100px" onClick={() => setCookieConsent(false)} data-sentry-element="Button" data-sentry-source-file="CookierBanner.tsx">
          Decline
        </Button>
      </Flex>
    </Box>;
}