import { mode } from '@chakra-ui/theme-tools';
import { globalButtons } from '../../global/buttons/mainbuttons';
// bg: mode('porcelain', 'black')(props),

export const baseButtons = {
  variants: {
    universalpawg: (props) => ({
      ...globalButtons.variants.universalpawg(props), 
      background: mode('linear-gradient(90deg, #2D7FDD 0%, #4C6FE5 100%)', 'radial-gradient(circle, rgba(1,84,254,1) 0%, rgba(55,192,223,1) 100%)')(props), 
      color: mode('white', 'text.100')(props), 
      borderWidth: '0px', 
      borderColor: mode('#0066cc', '#fff')(props), 
      fontWeight: '400',
      letterSpacing: '0px',
      transition: '0.2s ease', 
      boxShadow: 'lifted',
      _hover: {
        boxShadow: 'insetComponentShadow', 
        transform: 'scale(0.99)', 
        borderWidth: '0px', 
        background: mode('linear-gradient(180deg, #2D7FDD 0%, #4C6FE5 100%)',  'radial-gradient(circle, rgba(1,84,254,1) 0%, rgba(55,192,223,1) 100%)')(props), 


      },
      _disabled: {
        background: 'linear-gradient(135deg, #f0f0f0, #004080)', // this would be good on full opac
        color: '#555', 
      }
    }), 


    cosmicpawg: (props) => ({
      ...globalButtons.variants.cosmicpawg(props), 
      background: mode('linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%)', 'buttongroup')(props), 
      color: mode('#000', '#fff')(props),
      borderWidth: '0px', 
      fontWeight: '400', 
      letterSpacing: '0px',
      boxShadow: 'lifted',
      _hover: {
        boxShadow: 'insetComponentShadow', 
        transform: 'scale(0.99)', 
      },
      _disabled: {
        background: 'linear-gradient(135deg, #f0f0f0, #004080)', 
        color: '#555', 
      }
    }),
  }
};
