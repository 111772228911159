import { baseGradients } from "./baseGradients";
import { invisiblegradients } from "./invisiblegradients";

// primary, highlight, border, border2, navbarbg, navbarborder, outsetcomponent, insetcomponent, 
// tablebg, primarybutton, tokeninput, uibackground, mastheadtextgradient, settingstextgradient, dextone


export const baseDark = {
  ...baseGradients, 
  ...invisiblegradients.dark, 
  primary: '#0154fe',
  highlight: '#00ffff',
  navbarselected: '#00ffff',
  tabselected: '#fff', 

  border: '#a1a1a1',
  border2: 'rgba(30, 30, 30)',

  navbarbg: '#000', 
  subnav: '#000', 
  navbarborder: '#a1a1a1',


  // insetComponent: 'linear-gradient(135deg, #3f3d56, #d1d1e9)',
  insetComponent: 'radial-gradient(circle, rgba(11, 61, 145, 0.5) 10%, #000)',
  // insetComponent: 'linear-gradient(135deg, #3f3d56, #000)', this could be a good one on diff chain 


  outsetComponent: `linear-gradient(135deg, #000, #001235 70%, #001a4d 100%), radial-gradient(circle at bottom left, rgba(0, 255, 255, 0.05) 0%, transparent 60%)`,
  swaptokeninput: 'linear-gradient(135deg, #001a4d 70%, #001a4d 85%, #000e24 100%), radial-gradient(circle at center, rgba(0, 255, 255, 0.07) 0%, transparent 40%)',

  automated: 'linear-gradient(to right, #fff, #fff, #00ffff, #fff, #fff, #00ffff)', 

  insetComponentDropdown: 'radial-gradient(circle, rgba(11, 61, 145, 1) 10%, #000)',

  uibackground:   `radial-gradient(at 0% 0%, rgba(3, 9, 26, 0.8) 0%, transparent 50%),
  radial-gradient(at 100% 0%, rgba(1, 84, 254, 0.15) 0%, transparent 25%),
  radial-gradient(at 10% 10%, rgba(1, 84, 254, 0.23) 0%, transparent 50%),
  radial-gradient(at 90% 100%, rgba(1, 84, 254, 0.2) 0%, transparent 25%),
  radial-gradient(at 0% 80%, rgba(1, 84, 254, 0.1) 0%, transparent 25%),
  radial-gradient(at 0% 100%, rgba(1, 84, 254, 0.15) 0%, transparent 25%),

  radial-gradient(at 100% 100%, rgba(3, 9, 26, 0.8) 0%, transparent 50%)`, 

  dashboardshell: `transparent`,

  // insetComponent1: 'linear-gradient(135deg, rgba(10, 10, 10, 0.5), rgba(10, 10, 40, 0.75), rgba(10, 10, 10, 0.5))', 
  tablebg: 'linear-gradient(135deg, rgba(17, 17, 17, 0.5), rgba(25, 20, 66, 0.5), rgba(17, 17, 17, 0.25), rgba(25, 20, 35, 0.5))', 



  primarybutton: 'linear-gradient(to bottom, #0154fe, #37C0DF)',


  
    mastheadtextgradient: `linear-gradient(
    135deg,
  #0154fe 0%,
  #4d8fff 25%,
  #80b0ff 50%,
  #b3d1ff 75%,
  #e6f2ff 100%
  )`, 
  settingstextgradient: `linear-gradient(
    135deg,
    #0154fe 0%,
    #4d8fff 25%,
    #80b0ff 50%,
    #b3d1ff 75%,
    #e6f2ff 100%
  )`, 

  // darker 100, lighter 200 
  homecard: {
    100: 'radial-gradient(rgba(10, 29, 86, 0.75), #090b16)',
    200: 'radial-gradient(at 0% 50%, rgba(10,29,86,0.75) 0%, transparent 50%), radial-gradient(at 100% 50%, rgba(10,29,86,0.75) 0%, transparent 58%), radial-gradient(at 50% 0%, rgba(10,29,86,0.75) 0%, transparent 50%),  radial-gradient(at 50% 100%, rgba(10,29,86,0.75) 0%, transparent 58%)',
  
   },
// 100 currently dark one, 500 light one 

  dextone: {
    100: '#171717', 
    200: '#0F0F0F',
    300: '#0f1420',
    400: '#0c0f19', 
    500: '#090b16', 
    600: '#080a14', 
    700: '#070912', 
    800: '#06080f', 
    900: '#05070d', 
  },
 
};

// primaryrgba: 'rgba(1, 84, 254, 1)',

// primaryrgba: 'rgba(1, 84, 254, 1)',
// highlightrgba: 'rgba(0, 255, 255, 0.25)',
// tealrgba: 'rgb(55, 192, 223, 1)',
