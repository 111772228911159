'use client';

import React from 'react';
import { Button, useTheme, Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverHeader, useToast, Box, Flex, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { ChevronDown, Check } from 'react-feather';
import Image from 'next/image';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
import { useSwitchChain, useChainId } from 'wagmi';
import { networkList } from '~/lib/config/network-config';
import { NAVBAR_ITEMS_HEIGHT } from '~/app/components/wallet/NavbarConnect/NavbarConnectButton';
function NetworkDropdown({
  displayProps = {}
}) {
  const chainId = useChainId();
  const networkConfig = useNetworkConfig();
  const chainName = networkConfig.networkShortName;
  const [showCheckIcon, setShowCheckIcon] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const {
    switchChain
  } = useSwitchChain();
  const toast = useToast();
  const theme = useTheme();
  useEffect(() => {
    if (chainId) {
      setShowCheckIcon(true);
    }
  }, [chainId]);
  const handleNetworkSwitch = async (networkChainId, onClose) => {
    try {
      await switchChain({
        chainId: networkChainId
      });
      onClose();
    } catch (error) {
      console.log(error);
      toast({
        title: 'Network Switch Error',
        description: "Couldn't switch the network. Please try again.",
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  };
  return <Popover trigger="hover" placement="bottom-start" onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)} data-sentry-element="Popover" data-sentry-component="NetworkDropdown" data-sentry-source-file="NetworkDropdown.tsx">
      {({
      onClose
    }) => <>
          <PopoverTrigger>
            <Flex align={{
          base: 'center',
          lg: 'flex-end'
        }} h="30px" justify={{
          base: 'flex-start',
          lg: 'center'
        }}>
          
              <Button h={NAVBAR_ITEMS_HEIGHT} px="2" variant="colormodetoggle" onClick={() => setIsOpen(!isOpen)} rightIcon={<ChevronDown size="18px" style={{
            marginRight: '-4px',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.01s'
          }} color={theme.colors.text[100]} />} {...displayProps}>
                <>
                  <Image width={20} height={20} alt="Network Name" src={networkConfig?.iconUrl} />
                  <Text display={{
                base: 'none',
                lg: 'flex'
              }} fontWeight="500" flex="1" px="1" color="text.100" fontSize="14px">
                    {chainName}
                  </Text>
                </>
              </Button>
            </Flex>
          </PopoverTrigger>
          <PopoverContent py="1" w="250px" height="auto" bg="gray.950" borderRadius="24px">
            <PopoverHeader fontSize="16px" color="text.100" borderBottomWidth="1px">
              Select Network
            </PopoverHeader>
            <PopoverBody>
              {networkList.map(network => <Box key={network.chainId} as="button" onClick={() => handleNetworkSwitch(network.chainId, onClose)} textAlign="left" _hover={{
            bg: 'gray.900'
          }} display="flex" alignItems="center" justifyContent="flex-start" py="2" px="1" borderRadius="16px" w="full">
                  <Flex pl="1">
                    <Image src={network.iconUrl} width={24} height={24} alt="Network Name" />
                  </Flex>
                  <Flex ml="2" alignItems="center">
                    <Text color="text.100" fontSize="14px">
                      {network.name}
                    </Text>
                    {showCheckIcon && chainId === network.chainId && <Flex ml="8px">
                        <Check color={theme.colors.primary} size="18px" />
                      </Flex>}
                  </Flex>
                </Box>)}
            </PopoverBody>
          </PopoverContent>
        </>}
    </Popover>;
}
export default NetworkDropdown;