
import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys)

  
const baseStyle = definePartsStyle({

  control: {
    padding: 3,
    borderRadius: 10,
    borderWidth: 1,  
    boxSize: 4, 
  },
  icon: {


   size: 4, 
 },
})

export const checkboxTheme = defineMultiStyleConfig({ baseStyle })