import { soneiumGradients } from './soneiumGradients';
import { invisiblegradients } from './invisiblegradients';

export const soneiumLight = {
  ...soneiumGradients,
  ...invisiblegradients.light,
  primary: 'rgba(93, 138, 180, 1)',
  highlight: 'rgba(219, 135, 230, 1)',
  navbarselected: 'rgba(219, 135, 230, 1)',
  tabselected: '#fff', 

  layoutbg: 'transparent',
  uibackground: `
    radial-gradient(
      circle at 50% 0%,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(240, 248, 248, 0.6) 75%,
      rgba(230, 245, 245, 0.4) 100%
    ),
    linear-gradient(
      to top right,
      rgba(240, 248, 248, 0.8), 
      rgba(223, 229, 138, 0.5),
      rgba(240, 248, 248, 0.8), 
      rgba(219, 135, 230, 0.5),
      rgba(240, 248, 248, 0.8), 
      rgba(91, 247, 254, 0.5), 
      rgba(240, 248, 248, 0.8), 
      rgba(93, 138, 180, 0.5)
    ),
    #f8fafa
  `,

  border: '#c0d8d8',
  border2: 'rgba(0, 168, 232, 0.2)',


  navbarborder: '#E6F7FC',
  navbarbg: 'rgba(255, 255, 255, 0.8)',
  tablebg: 'rgba(240, 248, 248, 1)',



  // Subtle gradients for components
  outsetComponent: 'linear-gradient(180deg, #FFFFFF 70%, #F0F8F8 85%, #E6F5F5 100%)',
  insetComponent: 'linear-gradient(135deg, #FFFFFF 70%, #F0F8F8 85%, #E6F5F5 100%)',
  insetComponentDropdown: 'linear-gradient(135deg, #FFFFFF 70%, #F0F8F8 85%, #E6F5F5 100%)',



  // Updated text gradients
  settingstextgradient: 'linear-gradient(135deg, #00A8E8 0%, #33B7ED 100%)',
  mastheadtextgradient: `linear-gradient(
    135deg,
    #00A8E8 0%,
    #1AB1EB 25%,
    #33BAEE 50%,
    #4CC3F1 75%,
    #66CCF4 90%,
    #b3e6fa 100%
  )`,

  // Primary button with subtle gradient
  primarybutton: 'linear-gradient(180deg, #00A8E8 70%, #14AFEB 85%, #28B6EE 100%)',

  // Updated color palette for the light theme
  dextone: {
    100: '#E6F7FC',
    150: '#CCF0FA',
    200: '#B3E8F7',
    250: '#99E0F5',
    300: '#80D8F2',
    400: '#00000',
    500: '#19B8E8',
    600: '#00A8E8',
    700: '#0096D0',
    800: '#0084B8',
    900: '#0072A0',
  },
};
