import { modeGradients } from "./modeGradients";
import { invisiblegradients } from "./invisiblegradients";

export const modeDark = {
  ...modeGradients, 
  ...invisiblegradients.dark, 
  primary: '#dffe00',
  highlight: '#FFF',
  navbarselected: '#fff', 
  tabselected: '#000', 


  border: '#a1a1a1',
  border2: 'rgba(51,51,51,0.2)',

  navbarbg: 'linear-gradient(135deg, rgba(5, 5, 5, 0.5), rgba(10, 10, 10, 0.5), rgba(5, 5, 5, 0.5))', 
  subnav: 'linear-gradient(135deg, rgba(5, 5, 5, 0.5), rgba(10, 10, 10, 0.5), rgba(5, 5, 5, 0.5))', 
  navbarborder: '#a1a1a1', 

  outsetComponent: 'linear-gradient(135deg, #000, #333, #000)', 
  insetComponent: 'linear-gradient(135deg, #1A1E23, #1E1E1A)', 
  dashboardshell: 'linear-gradient(135deg, #000, #333, #000)', 

  
  swaptokeninput: 'linear-gradient(135deg, #233142, #111)', 

  swapcard1: '#000', 
  swapcard2: '#000', 
  // this is used currently for table glow class. adjust. 


  uibackground: 'linear-gradient(135deg, #000, #333, #000)', 

  settingstextgradient: 'linear-gradient(to left, #DFFE00, #60FE00)',
  mastheadtextgradient: 'linear-gradient(to left, #DFFE00, #60FE00)',
  primarybutton: 'linear-gradient(to right, #DFFE00, #60FE00)',


  // needs a mastheadtextgradient linear gradient like op/base on dark
  dextone: {
    100: '#171717', 
    200: '#0F0F0F',
    300: '#0f1420',
    400: '#0c0f19', 
    500: '#090b16', 
    600: '#080a14', 
    700: '#070912', 
    800: '#06080f', 
    900: '#05070d', 
},
};
