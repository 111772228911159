import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { http } from 'viem';
import { arbitrum, base, mode, optimism, soneiumMinato } from 'wagmi/chains';
import { CHAIN_MAIN, CHAIN_MAIN_NAME } from '~/constants';

declare module 'wagmi' {
  interface Register {
    config: typeof wagmiConfig;
  }
}

const getChains = (chainId) => {
  switch (chainId) {
    case 34443: // Mode network
      return [mode];
    case 8453: // Base network
      return [base];
    case 10: // Optimism
      return [optimism];
    case 42161: // Arbitrum
      return [arbitrum];
    case 1946: // Soneium Testnet
      return [soneiumMinato]; 
    default:
      throw new Error(`Unsupported chain ID: ${chainId}`);
  }
};

// do we need a new wagmi config project id here?

export const wagmiConfig = getDefaultConfig({
  appName: CHAIN_MAIN_NAME,
  projectId: 'a1590566262bad7e5d195ce57b46293b',
  // @ts-ignore
  chains: getChains(CHAIN_MAIN),
  transports: {
    [mode.id]: http(process.env.NEXT_PUBLIC_MODE_RPC),
    [base.id]: http(process.env.NEXT_PUBLIC_BASE_RPC),
    [optimism.id]: http(process.env.NEXT_PUBLIC_OPTIMISM_RPC),
    [arbitrum.id]: http(process.env.NEXT_PUBLIC_ARBITRUM_RPC),
    [soneiumMinato.id]: http(process.env.NEXT_PUBLIC_MINATO_RPC),


  },
  ssr: true, // If your dApp uses server side rendering (SSR)
});
